var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm"}},[(_vm.title)?_c('b-card',[_c('b-card-header',{staticClass:"mt-0 pt-0 p-0",class:_vm.nopading ? '' : 'pb-2'},[_c('div',{staticClass:"mt-0 d-flex align-items-center"},[_c('span',{staticClass:"mx-05",staticStyle:{"font-size":"1.286rem","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.custom_status)?_c('div',[_vm._t("custom_status")],2):_vm._e(),_c('div',[(_vm.showStatus && _vm.showStatus.removed != 'null')?_c('b-badge',{staticClass:"text-capitalize mr-1 justify-align-content-lg-start",attrs:{"active":"","pill":"","variant":("light-" + (_vm.getStatus(_vm.showStatus.removed).color))}},[_vm._v(" "+_vm._s(_vm.getStatus(_vm.showStatus.removed).name)+" ")]):_vm._e(),(_vm.showStatus && _vm.showStatus.status && _vm.$i18n.locale == 'en')?_c('b-badge',{staticClass:"text-capitalize mr-1 justify-align-content-lg-start",attrs:{"active":"","pill":"","variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.showStatus.status.name || _vm.showStatus.status.name_local)+" ")]):_vm._e(),(_vm.showStatus && _vm.showStatus.status && _vm.$i18n.locale == 'ar')?_c('b-badge',{staticClass:"text-capitalize mr-1 justify-align-content-lg-start",attrs:{"active":"","pill":"","variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.showStatus.status.name_local || _vm.showStatus.status.name)+" ")]):_vm._e(),(
                _vm.showStatus &&
                  _vm.showStatus.admission_type &&
                  _vm.$i18n.locale == 'en'
              )?_c('b-badge',{staticClass:"text-capitalize mr-1 justify-align-content-lg-start",attrs:{"active":"","pill":"","variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.showStatus.admission_type.name || _vm.showStatus.admission_type.name_local)+" ")]):_vm._e(),(
                _vm.showStatus &&
                  _vm.showStatus.admission_type &&
                  _vm.$i18n.locale == 'ar'
              )?_c('b-badge',{staticClass:"text-capitalize mr-1 justify-align-content-lg-start",attrs:{"active":"","pill":"","variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.showStatus.admission_type.name_local || _vm.showStatus.admission_type.name)+" ")]):_vm._e()],1)]),_vm._t("edit")],2),(_vm.kh)?_c('table',[_vm._t("default")],2):_vm._e(),(!_vm.kh)?_c('table',{staticClass:"table table-striped custom_table text_nobreak"},[_c('tbody',{staticStyle:{"text-align":"center"}},[_vm._l((_vm.transform(_vm.data, _vm.only, _vm.except)),function(item,key){return _c('tr',{key:key},[_c('th',{attrs:{"scope":"row","width":'20%'}},[_vm._v(" "+_vm._s(_vm.$t(("Global." + (item.key))))+" ")]),(item.value && typeof item.value == 'object')?_c('td',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.transform(item.value, null, _vm.except)),function(row,key){return _c('div',{key:key,staticClass:"d-flex"},[_c('div',{staticClass:"d-flex font-weight-bolder mr-05"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(("Global." + (row.key))))+" ")]),_vm._v(" "),(row.value && typeof row.value == 'object')?_c('span',[_vm._v("(")]):_c('span',[_vm._v(":")])]),(row.value && typeof row.value == 'object')?_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.transform(
                        row.value,
                        null,
                        _vm.except
                      )),function(inner,key){return _c('div',{key:key},[(inner.value && typeof inner.value == 'object')?_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.transform(
                            inner.value,
                            null,
                            _vm.except
                          )),function(child,key){return _c('div',{key:key},[_c('span',[_vm._v(_vm._s(child.value || '-'))]),_c('span')])}),0):_c('span',[(
                            (_vm.customShow && !_vm.customShow.includes(inner.key)) ||
                              !_vm.customShow
                          )?_c('div',[_vm._v(" "+_vm._s(_vm.expectKey && _vm.expectKey.includes(inner.key) ? '' : inner.value || '-')+" "),_c('span',[_vm._v(_vm._s(_vm.expectKey && _vm.expectKey.includes(inner.key) ? '' : key + 1 == _vm.checkIfEnd(_vm.transform(row.value, null, _vm.except)) ? '' : ','))])]):_c('div',[(inner.key == 'gender')?_c('span',[_vm._v(" "+_vm._s(inner.value == 1 ? _vm.$t('Global.male') : _vm.$t('Global.female'))+" ")]):_vm._e()])])])}),0):_vm._e(),(
                      (_vm.customShow && !_vm.customShow.includes(row.key)) ||
                        !_vm.customShow
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.expectKey && _vm.expectKey.includes(row.key) ? '' : row.value || '-')+" "),_c('span',[_vm._v(_vm._s(_vm.expectKey && _vm.expectKey.includes(row.key) ? '' : key + 1 == _vm.checkIfEnd(_vm.transform(item.value, null, _vm.except)) ? '' : ','))])]):_c('span',[(
                        (_vm.customShow && !_vm.customShow.includes(row.key)) ||
                          !_vm.customShow
                      )?_c('div',[_vm._v(" "+_vm._s(_vm.expectKey && _vm.expectKey.includes(row.key) ? '' : row.value || '-')+" "),_c('span',[_vm._v(_vm._s(_vm.expectKey && _vm.expectKey.includes(row.key) ? '' : key + 1 == _vm.checkIfEnd( _vm.transform(item.value, null, [ null, '', 'id', 'removed' ]) ) ? '' : ','))])]):_c('div',[(row.key == 'gender')?_c('span',[_vm._v(" "+_vm._s(row.value == 1 ? _vm.$t('Global.male') : _vm.$t('Global.female'))+" ")]):_vm._e()])])])}),0):_c('td',[(
                    (_vm.customShow && !_vm.customShow.includes(item.key)) ||
                      !_vm.customShow
                  )?_c('div',[_vm._v(" "+_vm._s(_vm.expectKey && _vm.expectKey.includes(item.key) ? '' : item.value || '-')+" "),_c('span',[_vm._v(_vm._s(_vm.expectKey && _vm.expectKey.includes(item.key) ? '' : ''))])]):_c('div',[(item.key == 'gender')?_c('span',[_vm._v(" "+_vm._s(item.value == 1 ? _vm.$t('Global.male') : _vm.$t('Global.female'))+" ")]):_vm._e()])])])}),_vm._t("custom_show")],2)]):_vm._e()],1):_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"mt-0 pt-0 pb-0 p-0"},[_c('div',{staticClass:"mt-0 d-flex align-items-center"},[(_vm.title)?_c('div',[_c('span',{staticClass:"mx-05",staticStyle:{"font-weight":"bolder","font-size":"18px"}},[_vm._v(" "+_vm._s(_vm.title)+" ")])]):_vm._e()]),_vm._t("edit")],2),_c('table',{staticClass:"table table-striped custom_table text_nobreak"},[_c('tbody',{staticStyle:{"text-align":"initial"}},[_vm._l((_vm.transform(_vm.data, _vm.only, _vm.except)),function(item,key){return _c('tr',{key:key},[_c('th',{attrs:{"scope":"row","width":'20%'}},[_vm._v(" "+_vm._s(_vm.$t(("Global." + (item.key))))+" ")]),_c('td',[(
                    (_vm.customShow && !_vm.customShow.includes(item.key)) ||
                      !_vm.customShow
                  )?_c('div',[_vm._v(" "+_vm._s(_vm.expectKey && _vm.expectKey.includes(item.key) ? '' : item.value || '-')+" "),_c('span',[_vm._v(_vm._s(_vm.expectKey && _vm.expectKey.includes(item.key) ? '' : ''))])]):_c('div',[(item.key == 'gender')?_c('span',[_vm._v(" "+_vm._s(item.value == 1 ? _vm.$t('Global.male') : _vm.$t('Global.female'))+" ")]):_vm._e()])])])}),_vm._t("custom_show")],2)])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }